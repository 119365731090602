<template>
    <div class="container">
        <el-table :data="tableData" :header-cell-style="{height: '40px', background: 'rgba(246,248,250,1)'}"
            :cell-style="{padding: '5px 0'}" header-align="center" tooltip-effect="dark" style="margin-top: 43px">
            <el-table-column label="订阅类型" width="300">
                <div class="order-name" slot-scope="scope">
                    <img src="@/assets/images/service/year.png" class="order-img" v-if="scope.row.detail.order_info.subscribe_count >= 3"/>
                    <img src="@/assets/images/service/month.png" class="order-img" v-else/>
                    <div class="order-info">
                        <div class="title" v-if="scope.row.detail.order_info.subscribe_count >= 3">梨子助教多年版</div>
                        <div class="title" v-else>梨子助教年费版</div>
                        <div class="tag">适合成长型机构经营</div>
                        <div class="price">¥{{scope.row.detail.order_info.unit_price / 100}}/年</div>
                    </div>
                </div>
            </el-table-column>
            <el-table-column label="订阅时长">
                <template slot-scope="scope">
                    {{scope.row.detail.order_info.subscribe_count}}/年
                </template>
            </el-table-column>
            <el-table-column label="学校数量">
                <template slot-scope="scope">
                    {{scope.row.detail.order_info.school_count}}/个
                </template>
            </el-table-column>
            <el-table-column label="小计">
                <template slot-scope="scope">
                    ¥{{scope.row.detail.price | numFixed}}
                </template>
            </el-table-column>
        </el-table>
        <div class="sum">
            合计：<span class="charge">¥{{item.detail.charge | numFixed}}</span>
        </div>
        <div class="pay">
            <div class="price">应付金额：<span class="charge">¥{{item.detail.charge | numFixed}}</span></div>
            <el-button type="success" class="btn" @click="handlePay">立即支付</el-button>
        </div>
        <div class="agree">
            <el-checkbox v-model="checked">阅读并同意<span>《梨子助教SaaS服务协议》</span></el-checkbox>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        item: {}
    },
    data() {
        return {
            tableData: [],
            checked: true
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        for (let i in this.item) {
            let o = {};
            o[i] = this.item[i];
            this.tableData.push(o);
        }
        console.log('this.tableData', this.tableData);
    },
    methods: {
        handlePay() {
            this.$emit('next', this.item);
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .order-name {
        display: flex;
        flex-direction: row;
        .order-img {
            width: 105px;
            height: 105px;
        }
        .order-info {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            .title {
                font-size: 18px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                margin-top: 5px;
            }
            .tag {
                font-size: 12px;
                font-weight: 400;
                color: #666666;
                margin-top: 8px;
            }
            .price {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                margin-top: 13px;
            }
        }
    }
    .sum {
        display: flex;
        justify-content: flex-end;
        margin-top: 23px;
        font-size: 14px;
        font-weight: 400;
        color: #888888;
        margin-right: 20px;
        .charge {
            font-weight: 500;
            color: #353535;
            margin-left: 10px;
        }
    }
    .pay {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        background: #f5f8fa;
        height: 124px;
        margin-top: 17px;
        .price {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #414141;
            margin-right: 20px;
            .charge {
                font-size: 20px;
                font-weight: 500;
                color: #f65d27;
                margin-left: 10px;
            }
        }
        .btn {
            margin-top: 25px;
            width: 100px;
            height: 36px;
            font-size: 14px;
            margin-right: 20px;
        }
    }
    .agree {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        font-size: 14px;
        span {
            color: #74be5f;
        }
    }
}
</style>