<template>
    <div class="container">
        <div class="body">
            <div class="mask-10" v-if="type == 10"></div>
            <div class="course" v-if="type == 10">
                <div class="left">
                    <img src="@/assets/images/service/month.png" alt="">
                </div>
                <div class="right">
                    <div class="title">梨子助教年费版</div>
                    <div class="tag">适合成长型机构经营</div>
                    <div class="price">¥5999/年</div>
                </div>
            </div>
            <div class="info" v-if="type == 10">
                <div class="info-item">
                    <div class="key">订阅年数</div>
                    <div class="value row">
                        <span class="bg" @click="minChange"><i class="el-icon-minus"></i></span>
                        <span class="input">{{editForm.num}}</span>
                        <span class="bg" @click="maxChange"><i class="el-icon-plus"></i></span>
                    </div>
                </div>
                <div class="info-item">
                    <div class="key">订购学校</div>
                    <div class="value" v-if="userType === USER_TYPE.SCHOOL">
                        {{school.name}}
                    </div>
                    <div class="value" v-if="userType === USER_TYPE.ORG_SCHOOL">
                        {{org_school.name}}
                    </div>
                    <div class="value" v-else-if="userType === USER_TYPE.ORG">
                        <el-select v-model="editForm.school_id" style="width: 448px" multiple clearable placeholder="请选择学校">
                            <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="bottom">
                    <div class="total">总计：<span class="icon">¥ </span><span class="price">{{editForm.price}}</span>
                    </div>
                    <div class="btn">
                        <el-button @click="routerChange(1)">取消</el-button>
                        <el-button type="primary" @click="handleOrder">提交订单</el-button>
                    </div>
                </div>
            </div>
            <div class="mask-20" v-if="type == 20"></div>
            <div class="course" v-if="type == 20">
                <div class="left">
                    <img src="@/assets/images/service/year.png" alt="">
                </div>
                <div class="right">
                    <div class="title">梨子助教多年版</div>
                    <div class="tag">适合成熟机构体系化经营</div>
                    <div class="price">¥4999/年</div>
                </div>
            </div>
            <div class="info" v-if="type == 20">
                <div class="info-item">
                    <div class="key">订阅年数</div>
                    <div class="value row">
                        <span class="bg" @click="minChange"><i class="el-icon-minus"></i></span>
                        <span class="input">{{editForm.num}}</span>
                        <span class="bg" @click="maxChange"><i class="el-icon-plus"></i></span>
                    </div>
                </div>
                <div class="info-item">
                    <div class="key">订购学校</div>
                    <div class="value" v-if="userType === USER_TYPE.SCHOOL">
                        {{school.name}}
                    </div>
                    <div class="value" v-else-if="userType === USER_TYPE.ORG">
                        <el-select v-model="editForm.school_id" style="width: 448px" multiple clearable placeholder="请选择学校">
                            <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="bottom">
                    <div class="total">总计：<span class="icon">¥ </span><span class="price">{{editForm.price}}</span>
                    </div>
                    <div class="btn">
                        <el-button @click="routerChange(1)">取消</el-button>
                        <el-button type="primary" @click="handleOrder">提交订单</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Core from 'core';

export default {
    components: {},
    props: {
        type: 0,
        price: 0
    },
    data() {
        return {
            editForm: {
                num: 1,
                school_id: '',
                price: 0
            },
            schoolList: [],
            school: Core.Data.getBelong(),
            org_school: Core.Data.getOrgCurrSchool(),
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        if (this.userType === this.USER_TYPE.ORG) {
            this.getSchoolAll();
        }
        this.editForm.price = this.price;
        if (this.type == 10) {
            this.editForm.num = 1;
        } else {
            this.editForm.num = 3;
            this.editForm.price = this.editForm.price * 3;
        }
    },
    methods: {
        getSchoolAll() {
            Core.Api.OrgSystem.getSchoolAll(this.school.id).then((res) => {
                this.schoolList = res.list;
            });
        },
        minChange() {
            if (this.type == 10) {
                if (this.editForm.num == 1) {
                    return this.$message.warning('不能再减啦');
                }
            }
            if (this.type == 20) {
                if (this.editForm.num == 3) {
                    return this.$message.warning('不能再减啦');
                }
            }
            this.editForm.num = this.editForm.num - 1;
            this.editForm.price = parseInt(this.editForm.price - this.price);
        },
        maxChange() {
            this.editForm.num = this.editForm.num + 1;
            this.editForm.price = parseInt(this.price * this.editForm.num);
            if (this.type == 10) {
                if (this.editForm.num > 2) {
                    this.$emit('changeValue', this.price, this.type);
                    this.editForm.price = 4999 * 3;
                    return;
                }
            }
        },
        handleOrder() {
            let school_ids;
            let count;
            let price;
            if (this.userType === this.USER_TYPE.ORG) {
                school_ids = this.editForm.school_id;
                count = school_ids.length;
                price = this.price * 100 * count * this.editForm.num;
            } else if (this.userType === this.USER_TYPE.SCHOOL) {
                price = this.price * 100 * this.editForm.num;
            }else if (this.userType === this.USER_TYPE.ORG_SCHOOL) {
                price = this.price * 100 * this.editForm.num;
            }
            Core.Api.OrgSystem.create(
                price,
                price,
                school_ids ? school_ids.join(',') : this.school.id,
                this.price * 100,
                20,
                this.editForm.num
            ).then((res) => {
                this.$emit('next', res);
            });
        },
        routerChange(type) {
            this.$emit('cancel', type);
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .mask-10 {
            width: 775px;
            height: 169px;
            opacity: 0.46;
            background: linear-gradient(90deg, #e7effb 0%, #c5d1db 100%);
            border-radius: 4px;
        }
        .mask-20 {
            width: 775px;
            height: 169px;
            opacity: 0.46;
            background: linear-gradient(90deg, #f2dfb1 0%, #deb77b 100%);
            border-radius: 4px;
        }
        .course {
            position: absolute;
            display: flex;
            flex-direction: row;
            top: 33px;
            margin-left: -400px;
            .left {
                width: 105px;
                height: 105px;
            }
            .right {
                margin-left: 16px;
                .title {
                    font-size: 20px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                    margin-top: 5px;
                }
                .tag {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 8px;
                }
                .price {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 13px;
                }
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 775px;
            margin-top: 27px;

            .info-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 30px;
                .key {
                    font-size: 14px;
                    font-weight: 400;
                    color: #8090a6;
                }
                .value {
                    margin-left: 40px;
                    &.row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .bg {
                        width: 32px;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        background: #f7f8fa;
                        color: #ccd3dd;
                        cursor: pointer;
                    }
                    .input {
                        width: 40px;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        background: #f7fafe;
                    }
                }
            }

            .bottom {
                display: flex;
                width: 775px;
                height: 60px;
                justify-content: space-between;
                align-items: center;
                margin-top: 180px;
                border-top: 1px solid #ededf1;

                .total {
                    font-size: 16px;
                    font-weight: 400;
                    color: #191f25;
                    .icon {
                        font-size: 14px;
                        font-weight: 500;
                        color: #f65d27;
                    }
                    .price {
                        font-size: 24px;
                        color: #f65d27;
                    }
                }
            }
        }
    }
}
</style>