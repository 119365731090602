<template>
    <div class="container">
        <div class="body">
            <div class="title">版本服务</div>
            <el-steps :active="active" finish-status="success" align-center class="step">
                <el-step title="选择服务"></el-step>
                <el-step title="确认订单信息"></el-step>
                <el-step title="确认付款"></el-step>
                <el-step title="完成订阅"></el-step>
            </el-steps>
            <select-service v-if="active === 0" :type="type" :price="price" @changeValue="changeValue"
                @cancel="routerChange" @next="handleNext">
            </select-service>
            <confirm-order v-if="active === 1" :item="item" @next="handleNext"></confirm-order>
            <pay-order v-if="active === 2" :item="item" @next="handleNext"></pay-order>
        </div>

    </div>
</template>

<script>
import ConfirmOrder from './components/ConfirmOrder.vue';
import PayOrder from './components/PayOrder.vue';
import SelectService from './components/SelectService';
import Core from 'core';
export default {
    components: { SelectService, ConfirmOrder, PayOrder },
    props: {},
    data() {
        return {
            active: 0,
            type: 0,
            price: 0,
            item: '',
            order_id: ''
        };
    },
    watch: {},
    computed: {},
    async created() {
        this.type = this.$route.query.type;
        this.price = this.$route.query.price;

        this.order_id = this.$route.query.id;
        if (this.order_id) {
            await this.getOrderDetail();
        }
        this.active = Number(this.$route.query.active) || 0;
    },
    mounted() {},
    methods: {
        handleNext(item) {
            console.log('handleNext item:', this.active, item)
            this.item = item;
            if (this.active++ > 2) {
                this.active = 0;
            }
        },
        async getOrderDetail() {
            let res = await Core.Api.OrgSystem.orderDetail(this.order_id);
            this.item = res;
        },
        routerChange(type) {
            console.log(`type`, type);
            switch (type) {
                case 1:
                    return this.$router.push({
                        path: '/system-service/service-list',
                        query: {}
                    });
            }
        },
        changeValue() {
            this.price = 4999;
            this.type = 20;
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        margin: 30px;
        padding: 20px;
        background: white;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 16px;
            font-weight: 500;
            color: #363d42;
            margin-bottom: 26px;
        }
        .step {
            width: 775px;
            margin: 0 auto;
        }
    }
}
</style>