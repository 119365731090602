<template>
    <div class="container">
        <div class="info">
            <div class="info-1">
                <div class="success">订单提交成功，请尽快付款！</div>
                <div class="tip">请您在15分钟内完成支付，否则订单会被自动取消</div>
            </div>
            <div class="info-2">
                总计<span class="icon">¥</span><span class="price">{{item.detail.charge | numFixed}}</span>
            </div>
            <div class="info-3">
                <span class="method">支付方式</span>
                <div class="column">
                    <div class="row">
                        <img src="@/assets/images/service/alipay.png" alt="" class="img">
                        <span>扫码支付</span>
                    </div>
                    <div class="qrcode" id="qrcode"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import Core from 'core';
export default {
    components: {},
    props: {
        item: {
            default: () => {
                return {
                    detail: {}
                }
            }
        }
    },
    data() {
        return {
            activityQrCodeImg: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        console.log(`created`, this.item)
    },
    mounted() {
        console.log(`mounted`, this.item)
        this.showQRcode();
    },
    methods: {
        async showQRcode() {
            let payRes = await Core.Api.Order.pay( this.item.detail.sn, 6 );
            this.$nextTick(function () {
                this.handleQRCodeGenerate(payRes.detail.code_url);
            });
        },
        handleQRCodeGenerate(link) {
            // 生成二维码
            document.getElementById('qrcode').innerHTML = ''; //清除上次二维码的数据
            let qrcode = new QRCode('qrcode', {
                width: 108,
                height: 108, // 高度
                text: link // 二维码内容
            });
            let qrcodeImgDom = qrcode._el.children[0];
            this.activityQrCodeImg = qrcodeImgDom
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream');
            return qrcode;
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    .info {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        width: 640px;
        margin: 70px auto;
        .info-1 {
            display: flex;
            width: 640px;
            flex-direction: column;
            padding-bottom: 20px;
            border-bottom: 1px solid #e3eaef;
            .success {
                font-size: 14px;
                font-weight: 400;
                color: #353535;
            }
            .tip {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #888888;
            }
        }
        .info-2 {
            margin-top: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #8090a6;

            .icon {
                font-weight: 500;
                color: #f65d27;
                margin-left: 54px;
            }
            .price {
                font-size: 24px;
                color: #f65d27;
                font-weight: 500;
            }
        }
        .info-3 {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 42px;
            .method {
                font-size: 14px;
                font-weight: 400;
                color: #8090a6;
            }
            .column {
                margin-left: 59px;
                display: flex;
                flex-direction: column;

                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: #313131;
                        margin-left: 5px;
                    }
                }
                .qrcode {
                    width: 108px;
                    height: 108px;
                    padding: 6px;
                    border: 1px solid #e7ecf1;
                    background: #fff;
                    margin-top: 12px;
                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>